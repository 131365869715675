@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "Niradei", sans-serif !important;
}

::-webkit-scrollbar {
  transition: all 0.3s ease-in-out;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  transition: all 0.3s ease-in-out;
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: all 0.3s ease-in-out;
  background: rgba(156, 156, 156, 0.9);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 0.3s ease-in-out;
  background: rgb(211, 211, 211);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes rotate3dAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

#rating_outline_icon {
  fill: gray;
}

#rating_outline:hover #rating_filled_icon {
  fill: #ff3f34;
}
